import { useState } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { sha256 } from "./sha256.js";
import { HASH_PASSWORD } from "../constants";

const PasswordDialog = ({
  setLoggedIn,
  setAccessKey,
  showPasswordDialog,
  setShowPasswordDialog,
}) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    const hash = await sha256(password);
    if (hash === HASH_PASSWORD) {
      setAccessKey(password);
      setLoggedIn(true);
      setShowPasswordDialog(false);
    } else {
      setError(true);
    }
  };

  const handleCloseDialog = () => {
    setShowPasswordDialog(false);
  };

  return (
    <Dialog open={showPasswordDialog} onClose={handleCloseDialog}>
      <DialogTitle>
        <Typography>Enter Password</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <form>
              <input
                type="password"
                value={password}
                variant="outlined"
                onChange={handlePasswordChange}
                required
              />
            </form>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Typography variant="body1" color="error">
                Incorrect password
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ width: 200, margin: 1 }}
          onClick={(e) => handlePasswordSubmit(e)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { PasswordDialog };

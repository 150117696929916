import { Grid, TextField, Button, Typography } from "@mui/material";

const SerialAndProductIdEnrollment = ({
  enrollmentFormData,
  handleEnrollmentFormDataChange,
  onHandleSubmit,
}) => {
  const handleSubmit = async (event, enrollmentRequestData) => {
    event.preventDefault();
    await onHandleSubmit(enrollmentRequestData);
  };

  return (
    <Grid container padding={4} display="flex" justifyContent="center">
      <Grid item xs={12} display="flex" justifyContent="center">
        <form>
          <Grid item xs={12} margin={3}>
            <Typography variant="body2" mb={0.5}>
              Enter the windows product id
            </Typography>
            <TextField
              sx={{ width: "100%" }}
              autoFocus
              id="productKey"
              label="Windows Product Id"
              variant="outlined"
              value={enrollmentFormData.productKey}
              onChange={handleEnrollmentFormDataChange}
              required
            />
          </Grid>
          <Grid item xs={12} margin={3} mt={3}>
            <Typography variant="body2" mb={0.5}>
              Enter the device serial number
            </Typography>
            <TextField
              id="serialNumber"
              label="Device Serial Number"
              variant="outlined"
              value={enrollmentFormData.serialNumber}
              onChange={handleEnrollmentFormDataChange}
              required
            />
          </Grid>
          <Grid item xs={12} margin={3}>
            <Typography variant="body2" mb={0.5}>
              Enter the id of the customer the device should be enrolled into.
              You can find this in the order.
            </Typography>
            <TextField
              id="komplettId"
              label="Komplett Customer Id"
              variant="outlined"
              value={enrollmentFormData.komplettId}
              onChange={handleEnrollmentFormDataChange}
              required
            />
          </Grid>

          {/* <Grid item xs={12} margin={3}>
            <Typography variant="body2" mb={0.5}>
              The skuId of the device is used to add further infromation to the
              device during enrollment, such as OS and description.
            </Typography>
            <TextField
              id="skuId"
              label="Sku Id"
              variant="outlined"
              value={enrollmentFormData.skuId}
              onChange={handleEnrollmentFormDataChange}
            />
          </Grid> */}
          <Button
            type="submit"
            variant="contained"
            color="success"
            sx={{ width: 200, margin: 3 }}
            onClick={(e) => handleSubmit(e)}
            disabled={
              enrollmentFormData.productKey === "" ||
              enrollmentFormData.serialNumber === "" ||
              enrollmentFormData.komplettId === ""
            }
          >
            Enroll Device
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export { SerialAndProductIdEnrollment };

import "./App.css";
import { useState } from "react";
import { Grid } from "@mui/material";
import { ReactComponent as IronStoneLogo } from "./logo/ironstone_logo_title.svg";

import { LoginView } from "./components/LoginView";
import { EnrollmentTabView } from "./components/EnrollmentTabView.js";

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [accessKey, setAccessKey] = useState("");

  return (
    <Grid container>
      <Grid item xs={12}>
        {!loggedIn && (
          <LoginView setLoggedIn={setLoggedIn} setAccessKey={setAccessKey} />
        )}
        {loggedIn && (
          <Grid container padding={2} display="flex" justifyContent="center">
            <Grid item xs={4}>
              <IronStoneLogo />
            </Grid>
          </Grid>
        )}
        {loggedIn && <EnrollmentTabView accessValue={accessKey} />}
      </Grid>
    </Grid>
  );
};

export default App;

import { Grid, Button, Typography } from "@mui/material";
import { useState } from "react";

import { ReactComponent as IronStoneLogo } from "../logo/ironstone_logo_title.svg";
import { PasswordDialog } from "./PasswordDialog";

const LoginView = ({ setLoggedIn, setAccessKey }) => {
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const onLoginClick = () => {
    setShowPasswordDialog(true);
  };

  return (
    <>
      <Grid
        container
        sx={{ display: "flex", justifyContent: "center", marginTop: 50 }}
      >
        <Grid item xs={6}>
          <IronStoneLogo />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "center", marginTop: 10 }}
      >
        <Button
          onClick={onLoginClick}
          disableElevation
          variant="contained"
          size="large"
          sx={{
            backgroundColor: "#FEBE10",
            color: "black",
            height: 50,
            width: 150,
          }}
        >
          <Typography variant="button">Sign in</Typography>
        </Button>
      </Grid>
      {showPasswordDialog && (
        <PasswordDialog
          setLoggedIn={setLoggedIn}
          setAccessKey={setAccessKey}
          showPasswordDialog={showPasswordDialog}
          setShowPasswordDialog={setShowPasswordDialog}
        />
      )}
    </>
  );
};

export { LoginView };

import { Grid, TextField, Button, Typography } from "@mui/material";

const HardwareHashEnrollment = ({
  enrollmentFormData,
  handleEnrollmentFormDataChange,
  onHandleSubmit,
}) => {
  const handleSubmit = async (event, enrollmentRequestData) => {
    event.preventDefault();
    onHandleSubmit(enrollmentRequestData);
  };

  return (
    <Grid container padding={4} display="flex" justifyContent="center">
      <Grid item xs={12} display="flex" justifyContent="center">
        <form>
          <Grid item xs={12} margin={3}>
            <Typography variant="body2" mb={0.5}>
              This is where you enter the hardware hash of the device.
            </Typography>
            <TextField
              sx={{ width: "100%" }}
              autoFocus
              id="hardwareHash"
              label="Hardware Hash"
              variant="outlined"
              value={enrollmentFormData.hardwareHash}
              onChange={(e) => handleEnrollmentFormDataChange(e)}
              required
              // input form should be very tall
              multiline
              rows={10}
            />
          </Grid>
          <Grid item xs={12} margin={3}>
            <Typography variant="body2" mb={0.5}>
              This is where you enter the id of the customer the device should
              be enrolled into. You can find this in the order.
            </Typography>
            <TextField
              id="komplettId"
              label="Komplett Customer Id"
              variant="outlined"
              value={enrollmentFormData.komplettId}
              onChange={(e) => handleEnrollmentFormDataChange(e)}
              required
            />
          </Grid>

          {/* <Grid item xs={12} margin={3}>
            <Typography variant="body2" mb={0.5}>
              The skuId of the device is used to add further infromation to the
              device during enrollment, such as OS and description.
            </Typography>
            <TextField
              id="skuId"
              label="Sku Id"
              variant="outlined"
              value={enrollmentFormData.skuId}
              onChange={(e) => handleEnrollmentFormDataChange(e)}
            />
          </Grid> */}
          <Button
            type="submit"
            variant="contained"
            color="success"
            sx={{ width: 200, margin: 3 }}
            onClick={(e) => handleSubmit(e)}
            disabled={
              enrollmentFormData.komplettId === "" ||
              enrollmentFormData.hardwareHash === ""
            }
          >
            Enroll Device
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export { HardwareHashEnrollment };

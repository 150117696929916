import { useState } from "react";
import { Tabs, Tab, Grid, Snackbar, Alert } from "@mui/material";

import { HardwareHashEnrollment } from "./HardwareHashEnrollment";
import { SerialAndProductIdEnrollment } from "./SerialAndProductIdEnrollment";
import { getCustomerInfo, postEnrollmentRequest } from "../api";

const EnrollmentTabView = ({ accessValue }) => {
  const [value, setValue] = useState(0);

  const emptyFormData = {
    serialNumber: "",
    productKey: "", // also known as windows product id
    hardwareHash: "",
    oemManufacturerName: "",
    komplettId: "",
    modelName: "",
    skuId: "",
  };

  const [enrollmentFormData, setEnrollmentFormData] = useState(emptyFormData);

  const [snackbar, setSnackbar] = useState({
    message: "",
    severity: "error",
    open: false,
  });

  const handleChange = (event, newValue) => {
    setEnrollmentFormData(emptyFormData);
    setValue(newValue);
  };

  const handleEnrollmentFormDataChange = (event) => {
    setEnrollmentFormData({
      ...enrollmentFormData,
      [event.target.id]: event.target.value,
    });
  };

  const getTabContent = () => {
    switch (value) {
      case 0:
        return (
          <SerialAndProductIdEnrollment
            enrollmentFormData={enrollmentFormData}
            handleEnrollmentFormDataChange={handleEnrollmentFormDataChange}
            onHandleSubmit={handleEnrollmentRequestSubmitted}
          />
        );
      case 1:
        return (
          <HardwareHashEnrollment
            enrollmentFormData={enrollmentFormData}
            handleEnrollmentFormDataChange={handleEnrollmentFormDataChange}
            onHandleSubmit={handleEnrollmentRequestSubmitted}
          />
        );
      default:
        return (
          <SerialAndProductIdEnrollment
            enrollmentFormData={enrollmentFormData}
            handleEnrollmentFormDataChange={handleEnrollmentFormDataChange}
            onHandleSubmit={handleEnrollmentRequestSubmitted}
          />
        );
    }
  };

  const handleEnrollmentRequestSubmitted = async () => {
    const { tenantId, numTenantsRegisteredOnKomplettId } =
      await getCustomerInfo(accessValue, enrollmentFormData.komplettId);

    if (!tenantId) {
      setSnackbar({
        message: `Did not find a related tenantId for the given komplettId: ${enrollmentFormData.komplettId}`,
        severity: "error",
        open: true,
        autoHideDuration: 6000,
      });
      return;
    }

    if (numTenantsRegisteredOnKomplettId > 1) {
      setSnackbar({
        message: "Too many tenants registered on this Komplett ID",
        severity: "error",
        open: true,
      });
      return;
    }

    if (numTenantsRegisteredOnKomplettId === 0) {
      setSnackbar({
        message: "No tenants registered on this Komplett ID",
        severity: "error",
        open: true,
      });
      return;
    }

    const enrollmentRequestData = {
      devices: [
        {
          serialNumber: enrollmentFormData.serialNumber,
          productKey: enrollmentFormData.productKey,
          hardwareHash: enrollmentFormData.hardwareHash,
          oemManufacturerName: enrollmentFormData.oemManufacturerName,
          modelName: enrollmentFormData.modelName,
          skuId: enrollmentFormData.skuId,
        },
      ],
    };

    const enrollmentResponse = await postEnrollmentRequest(
      accessValue,
      tenantId,
      enrollmentRequestData
    );

    if (enrollmentResponse.status === 201) {
      setEnrollmentFormData(emptyFormData);
      setSnackbar({
        message: "Enrollment request successfully sent",
        severity: "success",
        open: true,
      });
      return;
    }

    setSnackbar({
      message: "Enrollment request failed.",
      severity: "error",
      open: true,
    });
  };

  return (
    <>
      <Grid container padding={2} display="flex" justifyContent="center">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="enrollment tabs"
        >
          <Tab label="Serial and Product ID" />
          <Tab label="Hardware Hash" />
        </Tabs>
        {getTabContent()}
        {
          <Snackbar
            open={snackbar.open}
            autoHideDuration={3000}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
          >
            <Alert severity={snackbar.severity}>{snackbar.message} </Alert>
          </Snackbar>
        }
      </Grid>
    </>
  );
};

export { EnrollmentTabView };

import axios from "axios";
import { APIM_BASE_URL, SUB_K } from "./constants";

const getCustomerInfo = async (accessKey, komplettCustomerId) => {
  const url = `${APIM_BASE_URL}/api/enrollment/${komplettCustomerId}/tenantId`;
  const response = await axios
    .get(url, {
      headers: {
        Authorization: accessKey,
        "Ocp-Apim-Subscription-Key": SUB_K,
      },
    })
    .catch(() => {
      return {};
    });

  return response.data ?? {};
};
const postEnrollmentRequest = async (accessKey, tenantId, data) => {
  const url = `${APIM_BASE_URL}/api/enrollment/${tenantId}/devicesEnrollment`;
  const response = await axios
    .post(url, data, {
      headers: {
        Authorization: accessKey,
        "Ocp-Apim-Subscription-Key": SUB_K,
      },
    })
    .catch((error) => {});

  return response ?? {};
};

export { getCustomerInfo, postEnrollmentRequest };
